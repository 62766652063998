<template>
	<div class="index-container">
		<div class="index-top overflow">
			<div class="float-left overflow" style="height: 100%;">
				<div :class="isActive == 'notification' ? 'tabs-btn-true float-left':'tabs-btn float-left'"
					@click="tabs('notification')">活动通知</div>
				<div :class="isActive == 'explain' ? 'tabs-btn-true float-left':'tabs-btn float-left'"
					@click="tabs('explain')">参赛说明</div>
				<div :class="isActive == 'requirement' ? 'tabs-btn-true float-left':'tabs-btn float-left'"
					@click="tabs('requirement')">征集要求</div>
				<div :class="isActive == 'study' ? 'tabs-btn-true float-left':'tabs-btn float-left'"
					@click="tabs('study')">学习资料</div>
			</div>
		</div>
		<div class="index-content">
			<!-- 活动通知 -->
			<div v-if="isActive == 'notification'">
				<div v-html="configContent" @click="showImg($event)" class="myImg"></div>
			</div>
			<!-- 参赛说明 -->
			<div v-if="isActive == 'explain'">
				<div v-html="configContent" @click="showImg($event)" class="myImg"></div>
			</div>
			<!-- 征集要求 -->
			<div v-if="isActive == 'requirement'">
				<div v-html="configContent" @click="showImg($event)" class="myImg"></div>
			</div>
			<!-- 学习资料 -->
			<div v-if="isActive == 'study'">
				<div v-html="configContent" @click="showImg($event)" class="myImg"></div>
			</div>
		</div>
		<div>
			<div class="bottom-btn"> 
				<el-checkbox v-model="computedChecked" class="check-box" @change="checkedChange"></el-checkbox>
				<span class="read-text">我已阅读：《活动通知》《参赛说明》《征集要求》《学习资料》</span>
				<div v-if="!isExpert && !isFilter" class="origin-btn" :style="checked ? '' : 'cursor:not-allowed;'" @click="store">我要参赛</div>
				<div v-if="!isExpert && !isFilter" class="yellow-btn" :style="checked ? '' : 'cursor:not-allowed;'" @click="show_course">我的参赛{{region_theme_text}}</div>
				<div v-if="isExpert && !isFilter" class="yellow-btn" :style="checked ? '' : 'cursor:not-allowed;'" @click="review">评价{{region_theme_text}}</div>
				<div v-if="isFilter" class="yellow-btn" :style="checked ? '' : 'cursor:not-allowed;'" @click="filter">筛查{{region_theme_text}}</div>
			</div>
		</div>
		<div class="imgDolg" v-show="imgPreview.show" @click.stop="imgPreview.show = false">
			<i class="el-icon-close" id="imgDolgClose" @click.stop="imgPreview.show = false"></i>
			<img @click.stop="imgPreview.show = true" :src="imgPreview.img" />
		</div>
	</div>
</template>

<script>
	import {
		Base64
	} from 'js-base64';
	const CryptoJS = require('crypto-js')
	const key = "cunshikeji";
	export default {
		name: 'index',
		data() {
			return {
				region_theme_text: JSON.parse(sessionStorage.getItem('region_theme_text')) ? JSON.parse(sessionStorage.getItem('region_theme_text')) : '课程',
				isActive: 'notification',
				configContent: '',
				imgPreview: {
					img: "",
					show: false
				},
				// 是否已经阅读
				checked: true,
				// 区分 专家，教育局，教师   true的时候是教育局和专家  false是教师
				isExpert:false,
				// 区分是否是筛查专家
				isFilter: false
			}
		},
		computed: {
			computedChecked: {
				get: function() {
					return this.checked 
				},
				set: function(newVal) {
					this.checked = newVal
				}
				
			}
		},
		methods: {
			edit() {
				localStorage.clear()
				sessionStorage.clear()
				// 退出
				this.$router.push({
					name: 'login'
				})
				history.pushState(null, null, document.URL);
				window.addEventListener("popstate", function(e) {
					history.pushState(null, null, document.URL);
				}, false);
			},
			showImg(e) {
				if (e.target.tagName == 'IMG') {
					this.imgPreview.img = e.target.src
					this.imgPreview.show = true
				}
			},
			tabs(name) {
				this.isActive = name
				this.configContent = ''
				this.getConfig(name)
			},
			//专家评审
			review() {
				if(this.checked) {
					this.$router.push({
						name: 'review'
					})
				} else {
					return false
				}
			},
			//查看我的参赛课程
			show_course() {
				if(this.checked) {
					this.$router.push({
						name: 'course'
					})
				} else {
					return false
				}
			},
			// 专家筛查
			filter() {
				if(this.checked) {
					this.$router.push({
						name: 'filter'
					})
				} else {
					return false
				}
			},
			checkedChange(val) {
				// this.checked = val
				this.computedChecked = val
				sessionStorage.setItem('checked',val)
			},
			//我要参赛
			store() {
				if(this.checked) {
					this.$router.push({
						name: 'store'
					})
				} else {
					return false
				}
			},
			getConfig(str) {
				let url = '?region_code=' + JSON.parse(sessionStorage.getItem('region_code'))
				if(str == 'notification') {
					this.http.notification(url).then(res => {
						if(res.data.data && res.data.data.competition_course_notification) {
							this.configContent = res.data.data.competition_course_notification.content
						}
					})
				} else if(str == 'explain') {
					this.http.explain(url).then(res => {
						if(res.data.data && res.data.data.competition_course_explain) {
							this.configContent = res.data.data.competition_course_explain.content
						}
					})
				} else if(str == 'requirement') {
					this.http.requirement(url).then(res => {
						if(res.data.data && res.data.data.competition_course_requirement) {
							this.configContent = res.data.data.competition_course_requirement.content
						}
					})
				} else if(str == 'study') {
					this.http.study(url).then(res => {
						if(res.data.data && res.data.data.competition_course_study) {
							this.configContent = res.data.data.competition_course_study.content
						}
					})
				}
			}
		},
		mounted() {
			this.getConfig('notification')
			if (this.$route.query.token != undefined) {
				let Base64_token = Base64.decode(this.$route.query.token)
				// Decrypt 解密
				let bytes = CryptoJS.AES.decrypt(Base64_token, key);
				let originalText = bytes.toString(CryptoJS.enc.Utf8);
				sessionStorage.setItem('token', JSON.stringify(originalText))
			}
			let userinfo = JSON.parse(sessionStorage.getItem('userInfo'))
			if(userinfo.role == 'competition_course_expert'){
				this.isExpert = true
			} else if(userinfo.role == 'competition_course_filter_expert') {
				this.isFilter = true
			}
			if(!sessionStorage.getItem('checked')) {
				this.checked = false
			}
		}
	}
</script>

<style scoped="scoped">
	.index-container {
		width: 68%;
		min-width: 1000px;
		height: 70vh;
		margin: 0 auto;
		padding: 1vw 8vw;
	}

	.index-top {
		/* height: 12%; */
		height: 30px;
		margin-bottom: 20px;
	}

	.index-content {
		width: 100%;
		height: 88%;
		background: #FFFEFF;
		/* box-shadow: 0px 2px 7px 0px rgba(31, 180, 249, 0.19), 0px 8px 57px 0px rgba(31, 180, 249, 0.64); */
		padding: 40px;
		box-sizing: border-box;
	}

	.index-content>div {
		height: 100%;
		font-size: 16px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #333333;
		line-height: 40px;
		overflow-y: scroll;
	}

	/*切换样式*/
	.tabs-btn {
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		margin-right: 20px;
		cursor: pointer;
		
		/* width: 130px;
		height: 45px;
		background: #FFFFFF;
		box-shadow: 0px 5px 5px 0px rgba(31, 180, 249, 0.26), 0px -5px 5px 0px rgba(31, 180, 249, 0.05);
		border-radius: 25px;
		text-align: center;
		line-height: 45px;
		cursor: pointer;
		font-weight: bold;
		color: #1FB4F9;
		margin-right: 20px; */
	}

	.tabs-btn-true {
		position: relative;
		font-size: 16px;
		font-weight: bold;
		color: #FFFFFF;
		margin-right: 20px;
		cursor: pointer;
		
		/* width: 130px;
		height: 45px;
		background: #1FB4F9;
		box-shadow: 0px 5px 5px 0px rgba(31, 180, 249, 0.26), 0px -5px 5px 0px rgba(31, 180, 249, 0.05);
		border-radius: 25px;
		text-align: center;
		line-height: 45px;
		cursor: pointer;
		font-weight: bold;
		margin-right: 20px;
		color: white; */
	}
	.tabs-btn-true::after {
		content: '';
		height: 3px;
		width: 40px;
		display: block;
		position: absolute;
		background-color: #FFFFFF;
		left: 13px;
		bottom: -8px;
	}
	.bottom-btn {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 20px;
	}
	.bottom-btn .check-box /deep/ .el-checkbox__inner {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}
	.bottom-btn .check-box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: #ffffff !important;
		border-color: #ffffff !important;
	}
	.bottom-btn .check-box /deep/ .el-checkbox__inner::after {
		height: 15px;
		left: 7px;
		border-color: #C0191F !important;
	}
	.bottom-btn .read-text {
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
	}
	.origin-btn {
		height: 40px;
		border-radius: 5px;
		background: #FB824E;
		padding: 0 28px;
		font-size: 16px;
		font-weight: 400;
		line-height: 40px;
		color: #FFFFFF;
		cursor: pointer;
		margin-left: 30px;
	}
	.origin-btn:hover {
		background: #ee5514;
	}
	.yellow-btn {
		height: 40px;
		border-radius: 5px;
		background: #FFBD59;
		padding: 0 10px;
		font-size: 16px;
		line-height: 40px;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
		margin-left: 20px;
	}
	.yellow-btn:hover {
		background: #fca420;
	}
	.myImg /deep/ img {
		cursor: pointer !important;
	}

	.myImg /deep/ img:hover {
		transform: scale(1.01);
		transition: all .3s linear;
	}

	/* 富文本图片放大 */
	.imgDolg {
		width: 100vw;
		height: 100vh;
		position: fixed;
		z-index: 9999;
		background-color: rgba(140, 134, 134, 0.6);
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.imgDolg img {
		height: 40%;
		width: auto;
	}

	#imgDolgClose {
		position: fixed;
		top: 35px;
		cursor: pointer;
		right: 7%;
		font-size: 50px;
		color: white;
	}
</style>
